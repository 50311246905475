import { render, staticRenderFns } from "./ArticleDetail.vue?vue&type=template&id=59b3acee&scoped=true&"
import script from "./ArticleDetail.vue?vue&type=script&lang=ts&"
export * from "./ArticleDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ArticleDetail.vue?vue&type=style&index=0&id=59b3acee&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b3acee",
  null
  
)

export default component.exports