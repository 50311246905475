





























import { Component, Vue } from 'vue-property-decorator';
import { GetArticleDetail } from '@/http/api/ArticleApi';
import { ElistText, IbreadcrumbItem } from '@/assets/model/interface';
import '@/assets/style/merge.less';
import Header from '@c/header/Header.vue';
import Breadcrum from '@c/breadcrum/Breadcrum.vue';
import FileEcho from '@c/upload/FileEcho.vue';
import PageFooter from '@c/footer/Footer.vue'

@Component({
  components: {
    Header,
    Breadcrum,
    FileEcho,PageFooter
  },
})
export default class ArticleDetail extends Vue {
  private loading: boolean = false;
  private articleData: any = '';
  private breadcrumList: IbreadcrumbItem[] = [
    { name: '首页', path: '/' },
    { name: '会员服务', path: '/serve' },
  ];

  private created() {
    this.getDetail((this.$route.query.articleId as string));
    if (this.$route.query.type) {
      this.breadcrumList.push({
        name: ElistText[(this.$route.query.type as string)],
        path: '/serve/' + this.$route.query.type,
      });
    }
    this.breadcrumList.push({
      name: '详情',
      path: '',
    });
  }
  private next(): void {
    this.$router.push({
      path: '/forum',
      query: {
        id: this.$route.query.articleId,
        title: this.articleData.title
      }
    })
  };
  // 获取文章数据列表
  private getDetail(id: string): void {
    this.loading = true;
    GetArticleDetail({ id }).then((res) => {
      console.log(res)
      this.loading = false;
      if (res.code === '000') {
        // const data = res.result;
        this.articleData = res.result;
      }
    });
  }
}
